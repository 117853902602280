<template>
	<div>
		<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
			<template slot="scopedSlots" slot-scope="{ columnData }">
				<a @click="RepossessionOrder(columnData.loanId)" :disabled="columnData.canAddRepossessionOrderFee == '不可触发'">{{
					$t(`table.finance.actionMap.1`)
				}}</a>
				<a-divider type="vertical" />
				<a @click="RepossessionOrderList(columnData.loanId)">{{ $t(`table.finance.customerFee.triggerList`) }}</a>
			</template>
			<template slot="action" slot-scope="{ columnData }">
				<a @click="Detail(columnData.loanId)">{{ $t('page.checkDetail') }}</a>
				<!-- <a-divider type="vertical" />
				<a @click="LuFee(columnData)">{{ $t('table.finance.customerFee.enterFees') }}</a> -->
				<a-divider type="vertical" />
				<a @click="uploadRepayment(columnData)">{{ $t('table.finance.customerFee.uploadRepayments') }}</a>
			</template>
		</search-list-table>
		<a-modal v-drag-modal v-model="RecordList.show" :title="$t(`table.finance.cloum.actionList`)" :footer="false">
			<p style="margin-bottom: 10px; font-size: 16px" v-for="item in RecordList.list" :key="item.id">
				{{ item.createTime }}:{{ $t(`table.finance.actionMap.0`) }}
			</p>
		</a-modal>
		<upload-repayment ref="uploadRepayment" @repaymentsInitTable="repaymentsInitTable"></upload-repayment>
	</div>
</template>
<script>
import { apiBranchList } from '@/api/branch'
import UploadRepayment from '@/views/businessComponents/UploadRepayment.vue'
import { apiGetFinanceList } from '@/api/loan'
import moment from 'moment'
import { apiRepossessionOrder, apiFeeInputList } from '@/api/check'
export default {
	data() {
		return {
			branchOfficeList: [],
			RecordList: {
				show: false,
				list: []
			},
			FeeModal: {
				selectList: [
					{ value: 1, disabled: false },
					{ value: 2, disabled: false },
					{ value: 3, disabled: false },
					{ value: 4, disabled: false }
				],
				show: false,
				id: '',
				type: 4,
				bankName: '',
				dataSource: [],
				columns: [
					{
						// 费用明细
						title: this.$t('table.finance.customerFee.cloum.detail'),
						scopedSlots: { customRender: 'detail' },
						align: 'center',
						width: '25%'
					},
					{
						// 金额
						title: this.$t('table.finance.customerFee.cloum.amount'),
						scopedSlots: { customRender: 'amount' },
						align: 'center',
						width: '25%'
					},
					{
						// 金额
						title: this.$t('table.finance.customerFee.cloum.time'),
						scopedSlots: { customRender: 'transactionTime' },
						align: 'center'
					},
					// {
					// 	// 金额
					// 	title: this.$t('table.finance.customerFee.periodInput'),
					// 	scopedSlots: { customRender: 'checkInstallments' },
					// 	align: 'center',
					// 	width: '15%'
					// },
					{
						// 操作
						title: this.$t('operate.operate'),
						scopedSlots: { customRender: 'action2' },
						align: 'center',
						width: '15%'
					}
				],
				amount: '',
				transactionTime: '',
				certificate: '',
				certificates: [],
				checkInstallments: '',
				installmentsNumbers: ''
			}
		}
	},
	components: { UploadRepayment },
	computed: {
		installmentsOptions() {
			let arr = []
			for (let index = 1; index < this.FeeModal.installmentsNumbers + 1; index++) {
				arr.push(index)
			}
			return arr
		},
		formSearchList() {
			return [
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.cloums.IdNumber',
					type: 'input',
					validateField: ['identityNum']
				},
				{
					label: 'table.applyInfo.cloums.caseStatus',
					type: 'select',
					validateField: ['loanStatuses'],
					selectOption: [1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010, 1011, 1012],
					prefix: 'table.finance.loanStatusMap'
				},
				{
					label: 'table.CustomerManagement.loanType',
					type: 'select',
					validateField: ['loanType'],
					selectOption: Array.from({ length: this.$loanMap.loanType.length }, (_, i) => i),
					prefix: 'table.applyInfo.financialLabel.loanMap'
				},
				{
					label: 'IPF ',
					type: 'select',
					validateField: ['ipfClear'],
					selectOption: [
						{
							value: '1',
							text: 'IPF Cleared'
						},
						{
							value: '0',
							text: 'IPF Uncleared'
						},
						{
							value: '2',
							text: 'No IPF'
						}
					],
					dataPreType: {
						value: 'value',
						text: 'text'
					}
				},
				{
					label: 'IPF Cleared Date',
					type: 'range-picker',
					validateField: ['ipfClearDate', { initialValue: [] }]
				},
				{
					label: 'Emergency ',
					type: 'select',
					validateField: ['emergencyClear'],
					selectOption: [
						{
							value: '1',
							text: 'Emergency Cleared'
						},
						{
							value: '0',
							text: 'Emergency Uncleared'
						},
						{
							value: '2',
							text: 'No Emergency'
						}
					],
					dataPreType: {
						value: 'value',
						text: 'text'
					}
				},
				{
					label: 'Emergency Cleared Date',
					type: 'range-picker',
					validateField: ['emergencyClearDate', { initialValue: [] }]
				},
				{
					label: 'Cash Pap ',
					type: 'select',
					validateField: ['cashPapClear'],
					selectOption: [
						{
							value: '1',
							text: 'Cash Pap Cleared'
						},
						{
							value: '0',
							text: 'Cash Pap Uncleared'
						},
						{
							value: '2',
							text: 'No Cash Pap'
						}
					],
					dataPreType: {
						value: 'value',
						text: 'text'
					}
				},
				{
					label: 'Cash Pap Cleared Date',
					type: 'range-picker',
					validateField: ['cashPapClearDate', { initialValue: [] }]
				},
				{
					label: 'table.applyInfo.loanApplyLabel.branchOffice',
					type: 'select',
					validateField: ['officeId'],
					selectOption: this.branchOfficeList,
					dataPreType: {
						value: 'id',
						text: 'storeName'
					}
				},
				{
					label: 'Repeat Client',
					type: 'select',
					validateField: ['repeatClient'],
					selectOption: [
						{
							repName: 'No',
							value: '0'
						},
						{
							repName: 'Yes ',
							value: '1'
						}
					],
					dataPreType: {
						value: 'value',
						text: 'repName'
					}
				},
				{
					label: 'table.applyInfo.cloums.disbursedTime',
					type: 'range-picker',
					validateField: ['applyTime', { initialValue: [] }]
				},
				{
					label: 'Closed Date',
					type: 'range-picker',
					validateField: ['closeTime', { initialValue: [] }]
				},
				{
					label: 'table.applyInfo.cloums.defaultDate',
					type: 'range-picker',
					validateField: ['defaultDate', { initialValue: [] }]
				}
			]
		},
		columns() {
			return [
				{
					// 案件ID
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					align: 'center',
					width: 80,
					fixed: 'left'
				},
				{
					// 案件ID
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'loanId',
					align: 'center',
					width: 150,
					fixed: 'left',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				{
					// 用户名
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'firstName',
					align: 'center',
					customRender: (v, o) => (v ? v : '') + ' ' + (o.middleName ? o.middleName : '') + ' ' + (o.lastName ? o.lastName : '')
				},
				{
					// 车牌号
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					dataIndex: 'registrationNumbers',
					align: 'center',
					customRender: (v) => (v ? v.join('/') : '-')
				},
				{
					// 身份ID
					title: this.$t('table.applyInfo.cloums.IdNumber'),
					dataIndex: 'identityNum',
					align: 'center'
				},
				{
					// 申请金额
					title: this.$t('table.applyInfo.cloums.principalAmount'),
					dataIndex: 'applyAmount',
					align: 'center',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					// 放款金额
					title: this.$t('table.applyInfo.cloums.putAmount'),
					dataIndex: 'disburseAmount',
					align: 'center',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					// 支付时间
					title: this.$t('table.applyInfo.cloums.disbursedTime'),
					dataIndex: 'disburseTime',
					align: 'center'
				},
				{
					title: this.$t('table.CustomerManagement.loanType'),
					dataIndex: 'loanType',
					align: 'center',
					customRender: (v, o) => {
						return this.$t(`table.applyInfo.financialLabel.loanMap.${v}`)
					}
				},
				{
					// 案件状态
					title: this.$t('table.applyInfo.cloums.caseStatus'),
					dataIndex: 'loanStatus',
					align: 'center',
					customRender: (v, o) => {
						return this.$t(`table.finance.loanStatusMap.${v}`)
					}
				},
				{
					// 逾期天数
					title: this.$t('table.applyInfo.cloums.overdue'),
					dataIndex: 'dueDays',
					align: 'center'
				},
				// {
				// 	// 收车通知
				// 	title: this.$t('table.finance.customerFee.tabs.2'),
				// 	scopedSlots: { customRender: 'scopedSlots' },
				// 	align: 'center'
				// },
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 250,
					fixed: 'right'
				}
			]
		}
	},
	methods: {
		moment: moment,
		initDataSource(initParams) {
			// if (!initParams.loanStatuses) {
			// 	initParams.loanStatuses = '1001,1002,1003,1004'
			// }
			return apiGetFinanceList(initParams)
		},
		Detail(id) {
			this.$router.push({
				name: 'menus.customerManagementMenu.lendingdetail',
				query: {
					id
				}
			})
		},
		uploadRepayment(columnData) {
			this.$refs.uploadRepayment.uploadRepayment(columnData.loanId)
		},
		repaymentsInitTable(pageNum) {
			this.$refs.searchListTable.initTable(pageNum)
		},
		// 收车记录
		RepossessionOrderList(id) {
			apiFeeInputList({ loanId: id, loanFeeType: 5 }).then((res) => {
				this.RecordList.list = res
				this.RecordList.show = true
			})
		},
		// 收车通知
		RepossessionOrder(id) {
			this.$confirm({
				title: this.$t(`table.finance.customerFee.tips2`),
				content: '',
				onOk: () => {
					apiRepossessionOrder({ loanId: id }).then((res) => {
						this.$message.success('success')
						this.$refs.searchListTable.initTable()
					})
				},
				onCancel() {}
			})
		},
		// 客户录入费用类型改变
		ChangeType() {
			this.FeeModal.certificates = []
			this.FeeModal.certificate = ''
			this.FeeModal.amount = ''
			this.FeeModal.transactionTime = ''
			this.FeeModal.bankName = ''
			this.FeeModal.checkInstallments = ''
			this.FeeModal.dataSource = []
		},
		// 删除
		Del(idx) {
			this.FeeModal.dataSource.splice(idx, 1)
		},
		AddData() {
			this.FeeModal.dataSource.push({
				detail: '',
				amount: '',
				transactionTime: ''
			})
		},
		// 录入客户费用
		LuFee(columnData) {
			if (columnData.loanStatus > 1004) {
				this.$message.error('Loan Cleared!')
				return false
			}
			this.FeeModal.id = columnData.loanId
			this.FeeModal.type = 4
			this.FeeModal.dataSource = []
			this.FeeModal.amount = ''
			this.FeeModal.transactionTime = ''
			this.FeeModal.certificates = []
			this.FeeModal.bankName = ''
			this.FeeModal.checkInstallments = ''
			this.FeeModal.installmentsNumbers = columnData.installmentsNumbers
			this.FeeModal.show = true
		}
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
			})
		})
	}
}
</script>
<style scoped lang="less">
.ant-input,
.ant-input-number,
.ant-select {
	width: 300px;
}
</style>
